import { Sentry } from '../../../../sentry';
import { Api } from '../../api';
import { CityCodes } from '../const/CityCodes';
import { CONSTANTS } from '../const/Constants';
import { EventTypes } from '../const/events';
import { magnumId } from '../const/MagnumCodes';
import { emptyUserIdHash, getUserIdHashForPCM } from '../helpers/getUserIdHash';
import { defaultCityId, getCurrentCityId } from '../helpers/locationHelpers';
import { EventEmitter } from '../services/eventEmitterService';
import { SessionStorageModel } from '../StorageModel/SessionStorageModel';

let zonePromise = null;

const errorMessages = {
  addressIsEmpty: 'AvailableZonesApiProvider: address is empty',
  coordsIsEmpty:
    'AvailableZonesApiProvider: lat or lng is empty in address by merchantId',
};

const sessionStorageKeys = 'availableZoneIds';

// tslint:disable-next-line: max-classes-per-file
export class AvailableZonesApiProvider {
  readonly userIdHash = getUserIdHashForPCM();
  cityId: string = getCurrentCityId() || defaultCityId;
  hardCodeMerchantUid = magnumId;
  zoneSessionStorage: SessionStorageModel;
  api: Api;
  m: any;
  static EMPTY_COORD_KEY = 'NO_COORD';

  constructor(m: any) {
    this.m = m;
    this.api = new Api(m);
    this.zoneSessionStorage = new SessionStorageModel();
  }

  getCoordInfoFromStorage() {
    const zones = JSON.parse(
      this.zoneSessionStorage.getValue(sessionStorageKeys)
    );
    return zones?.[this.cityId] || [];
  }

  getZoneIDDefault() {
    switch (this.cityId) {
      case CityCodes.ALMATY:
        return 'Magnum_ZONE1';
      case CityCodes.ASTANA:
        return 'Magnum_ZONE5';
      case CityCodes.KASKELEN:
        return CityCodes.KASKELEN;
      case CityCodes.SHYMKENT:
        return CityCodes.SHYMKENT;
      case CityCodes.BESAGASH:
        return CityCodes.BESAGASH;
      default:
        return null;
    }
  }

  fetchZoneByMerchant(response) {
    const lat = response.geo_point_dto?.lat;
    const lng = response.geo_point_dto?.lon;
    if (!lat || !lng) {
      throw new Error(errorMessages.coordsIsEmpty);
    }

    const config = (xhr) => {
      xhr.ignoreHandlingError404 = true;
      xhr.ignoreHandlingError500 = true;
    };

    return this.api.getZoneByMerchant(
      this.hardCodeMerchantUid,
      lat,
      lng,
      config
    );
  }

  async fetchZone(payload: {
    userIdHash: string;
    cityId: string;
    merchantUid: string;
  }): Promise<string[]> {
    let customZones = [];

    try {
      const response = await this.api.getAddressZoneInfo(payload);

      if (CONSTANTS.customZoneAvailable) {
        customZones = response?.zones ?? [];
      }

      if (!CONSTANTS.citiesWithAvailableZones.includes(this.cityId)) {
        return customZones;
      }

      const lat = response?.geo_point_dto?.lat;
      const lng = response?.geo_point_dto?.lon;

      if (!lat || !lng) {
        return [AvailableZonesApiProvider.EMPTY_COORD_KEY, ...customZones];
      }

      const zoneResponse = await this.fetchZoneByMerchant(response);
      const zones = [zoneResponse.zoneId, ...customZones];

      return zones;
    } catch (error) {
      const defaultZones = [this.getZoneIDDefault(), ...customZones];

      if (
        error.code !== 404 &&
        error.message !== errorMessages.addressIsEmpty &&
        error.message !== errorMessages.coordsIsEmpty &&
        this.userIdHash !== emptyUserIdHash
      ) {
        console.error('zoneResponse error ', error);
        Sentry?.captureMessage('zoneResponse error');
      }
      return defaultZones;
    }
  }

  async getAvailableZones(): Promise<string[]> {
    EventEmitter.emit(EventTypes.HIDE_MAGNUM_PRODUCTS, {});

    // getting new magnumZone model from SessionStorage
    const zonesFromSession = JSON.parse(
      this.zoneSessionStorage.getValue(sessionStorageKeys)
    );

    // if new magnumZone model contains value return it
    const zonesByCity = zonesFromSession?.[this.cityId];
    if (zonesByCity && Array.isArray(zonesByCity)) {
      return this.mutateZones(zonesByCity);
    }

    if (!zonePromise) {
      const payload = {
        userIdHash: this.userIdHash,
        cityId: this.cityId,
        merchantUid: this.hardCodeMerchantUid,
      };

      zonePromise = this.fetchZone(payload);
    }

    const zones = await zonePromise;

    this.updateStorage(zones);
    return this.mutateZones(zones);
  }

  updateStorage(zones: string | string[]) {
    // getting storage value again for actual data
    const valueToSet =
      JSON.parse(this.zoneSessionStorage.getValue(sessionStorageKeys)) ?? {};
    // updating existing model by city
    if (valueToSet) {
      valueToSet[this.cityId] = zones;
    }

    // assign updated zoneModel into session
    this.zoneSessionStorage.saveValue({
      name: sessionStorageKeys,
      value: JSON.stringify(valueToSet),
    });
  }

  mutateZones(zones: string[]) {
    const filteredZones = this.replaceEmptyZone(zones);
    return this.mutateByFakeZone(filteredZones);
  }

  replaceEmptyZone(zones: string[]) {
    return zones
      .map((zone: string) => {
        if (zone === AvailableZonesApiProvider.EMPTY_COORD_KEY) {
          return this.getZoneIDDefault();
        }
        return zone;
      })
      .filter((zone: string) => zone);
  }

  mutateByFakeZone(zones: string[]) {
    const isZoneOne =
      CONSTANTS.hideMagnumProducts.enabled &&
      zones.includes(CONSTANTS.hideMagnumProducts.firstZoneCode);

    if (isZoneOne) {
      return zones.map((zone: string) =>
        zone === CONSTANTS.hideMagnumProducts.firstZoneCode
          ? CONSTANTS.hideMagnumProducts.fakeZoneCode
          : zone
      );
    }

    return zones;
  }

  cleanZonesStorage() {
    this.updateStorage(null);
  }
}
