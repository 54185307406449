export enum EventSpecCommonKeys {
  path = 'path',
  ref = 'ref',
  source = 'source',
  started_from = 'started_from',
  started_by = 'started_by',
  page_category = 'page_category',
  inner_source = 'inner_source',
  product = 'product',
  promo = 'promo',
  referrer = 'referrer',
  ssohash = 'ssohash',
  available_filters = 'available_filters',
  category_id = 'category_id',
  product_type = 'product_type',
  listing_position = 'listing_position',
  applied_filter = 'applied_filter',
  value = 'value',
}

export enum EventSpecCategoryKeys {
  category_level = 'category_level',
  category_string = 'category_string',
  category_level_1 = 'category_level_1',
  category_level_2 = 'category_level_2',
  category_level_3 = 'category_level_3',
  category_level_4 = 'category_level_4',
  category_level_1_count = 'category_level_1_count',
  category_level_2_count = 'category_level_2_count',
  category_level_3_count = 'category_level_3_count',
  category_level_1_changed = 'category_level_1_changed',
  category_level_2_changed = 'category_level_2_changed',
  category_level_3_changed = 'category_level_3_changed',
}

export enum EventSpecSearchKeys {
  search_term = 'search_term',
  query_id = 'query_id',
}

export enum EventSpecProductKeys {
  product_id = 'product_id',
  product_name = 'product_name',
  product_category = 'product_category',
  brand = 'brand',
  price = 'price',
  credit_monthly_price = 'credit_monthly_price',
  offers_quantity = 'offers_quantity',
  kd_offers_count = 'kd_offers_count',
  quantity = 'quantity',
  promo_sku_count = 'promo_sku_count',
  created_time = 'created_time',
  has_configurator = 'has_configurator',
  image_count = 'image_count',
  rating = 'rating',
  stock = 'stock',
  has_big_picture = 'has_big_picture',
  has_description = 'has_description',
  has_video_review = 'has_video_review',
  mabaya_adds_count = 'mabaya_adds_count',
  number_of_orders = 'number_of_orders',
  review_count = 'review_count',
  is_discount = 'is_discount',
  test_discount_amount = 'test_discount_amount',
  seller_rating = 'seller_rating',
}

export enum EventSpecSupplierKeys {
  suppliers = 'suppliers',
  supplier = 'supplier',
  supplier_id = 'supplier_id',
  supplier_name = 'supplier_name',
  supplier_count = 'supplier_count',
  supplier_price = 'supplier_price',
  supplier_position = 'supplier_position',
  supplier_rating = 'supplier_rating',
  supplier_review_count = 'supplier_review_count',
}

export enum EventSpecCartKeys {
  cart_id = 'cart_id',
  cart_quantity = 'cart_quantity',
  cart_quantity_discount = 'cart_quantity_discount',
  cart_size = 'cart_size',
  delivery_price = 'delivery_price',
  subtotal_price = 'subtotal_price',
  suborder_count = 'suborder_count',
  total_price = 'total_price',
  delete_quantity = 'delete_quantity',
  selection_apply = 'selection_apply',
  supplier_name_not_in_stock = 'supplier_name_not_in_stock',
  supplier_id_not_in_stock = 'supplier_id_not_in_stock',
  saved_address_count = 'saved_address_count',
  delivery_suborders_count = 'delivery_suborders_count',
  postomat_suborders_count = 'postomat_suborders_count',
  pickup_suborders_count = 'pickup_suborders_count',
  available_delivery_method = 'available_delivery_method',
  delivery_method = 'delivery_method',
  cart_quantity_not_available = 'cart_quantity_not_available',
  cart_quantity_price_change = 'cart_quantity_price_change',
  magnum_subtotal_price = 'magnum_subtotal_price',
}

export enum EventSpecOfferKeys {
  delivery_cost = 'delivery_cost',
  delivery_date = 'delivery_date',
  delivery_options = 'delivery_options',
  sort_by = 'sort_by',
  sort_applied = 'sort_applied',
  applied_filter = 'applied_filter',
  delivery_filter_value = 'delivery_filter_value',
  product_page = 'product_page',
  result_count = 'result_count',
  search_term = 'search_term',
}

export enum EventSpecAdKeys {
  is_promoted = 'is_promoted',
  ads_supplier_id = 'ads_supplier_id',
  ads_unique_id = 'ads_unique_id',
}

export enum EventSpecPcmKeys {
  cross_sell_backdrop_product_id = 'cross_sell_backdrop_product_id',
  cross_sell_backdrop_product_name = 'cross_sell_backdrop_product_name',
  cross_sell_backdrop_listing_position = 'cross_sell_backdrop_listing_position',
  cross_sell_backdrop_count = 'cross_sell_backdrop_count',
  block_id = 'block_id',
  block_name = 'block_name',
}

export enum EventMarketingKeys {
  utm_campaign = 'utm_campaign',
  utm_source = 'utm_source',
  utm_medium = 'utm_medium',
  utm_content = 'utm_content',
  utm_term = 'utm_term',
  campaign_id = 'campaign_id',
  fbclid = 'fbclid',
  ttclid = 'ttclid',
  gclid = 'gclid',
}
