export enum EventType {
  // Refunds
  clickedRefundButton = 'clickedRefundButton',
  viewedCreateRefundButton = 'viewedCreateRefundButton',
  viewedRefundCreationPage = 'viewedRefundCreationPage',
  clickedOnProductSelectInRefundCreation = 'clickedOnProductSelectInRefundCreation',
  viewedRefundCreationWithReasonPage = 'viewedRefundCreationWithReasonPage',
  clickedOnProductQuantityIncrement = 'clickedOnProductQuantityIncrement',
  clickedOnRefundReasonSelect = 'clickedOnRefundReasonSelect',
  clickedOnRefundCommentInput = 'clickedOnRefundCommentInput',
  clickedOnRefundUploadPhoto = 'clickedOnRefundUploadPhoto',
  viewedRefundCreationAlert = 'viewedRefundCreationAlert',
  clickedOnRefundCreationButton = 'clickedOnRefundCreationButton',
  viewedRefundCreationTYPPage = 'viewedRefundCreationTYPPage',
  clickedOnBackToOrdersAfterRefundCreation = 'clickedOnBackToOrdersAfterRefundCreation',
  clickedOnRefundsListLinkInOrders = 'clickedOnRefundsListLinkInOrders',
  viewedRefundsListPage = 'viewedRefundsListPage',
  clickedOnRefundDetailsLinkInRefunds = 'clickedOnRefundDetailsLinkInRefunds',
  viewedRefundDetailsLinkInOrders = 'viewedRefundDetailsLinkInOrders',
  clickedOnRefundDetailsLinkInOrders = 'clickedOnRefundDetailsLinkInOrders',
  viewedRefundDetailsPage = 'viewedRefundDetailsPage',
  clickedOnCancelRefundButton = 'clickedOnCancelRefundButton',
  viewedCancelRefundPage = 'viewedCancelRefundPage',
  clickedOnCancelRefundConfirmButton = 'clickedOnCancelRefundConfirmButton',
  viewedConfirmOnCancelResultPage = 'viewedConfirmOnCancelResultPage',
  clickedOnChangeDateAndAddressButton = 'clickedOnChangeDateAndAddressButton',
  clickedOnSetDateAndAddressButton = 'clickedOnSetDateAndAddressButton',
  changeDateForDeliveryInRefund = 'changeDateForDeliveryInRefund',
  clickedOnStartRefundDisputeButton = 'clickedOnStartRefundDisputeButton',
  viewedRefundDisputeAlert = 'viewedRefundDisputeAlert',
  clickedOnDisputeAlertButton = 'clickedOnDisputeAlertButton',
  viewedRefundDisputeTYP = 'viewedRefundDisputeTYP',
  clickedOnViewMoreComment = 'clickedOnViewMoreComment',
  viewedRefundRequiredAddressAlert = 'viewedRefundRequiredAddressAlert',
  viewedRefundsListButton = 'viewedRefundsListButton',

  // Favorites
  addedProductToFavorites = 'addedProductToFavorites',
  removedProductFromFavorites = 'removedProductFromFavorites',
  viewedFavoritesPage = 'viewedFavoritesPage',

  // Cart
  viewedCartPage = 'viewedCartPage',
  clickedAddToCartButton = 'clickedAddToCartButton',
  clickedAddToCartButtonProductList = 'clickedAddToCartButtonProductList',
  clickedOtherMerchantSelect = 'clickedOtherMerchantSelect',
  clickedAnalogGoodsSelect = 'clickedAnalogGoodsSelect',
  viewedAnalogGoodsBackdrop = 'viewedAnalogGoodsBackdrop',
  viewedCartOutOfStockButton = 'viewedCartOutOfStockButton',
  clickedBuyNowButton = 'clickedBuyNowButton',
  viewedItemConfiguratorBackdrop = 'viewedItemConfiguratorBackdrop',
  addingToCartErrorOccurred = 'addingToCartErrorOccurred',
  addedProductInCart = 'Added Product', // use in 3507_cross-sell
  removedProductFromCart = 'removedProductFromCart',
  removedNotAvailableProductFromCart = 'removedNotAvailableProductFromCart',
  clickedCartProductQuantityIncrease = 'clickedCartProductQuantityIncrease',
  clickedCartProductQuantityDecrease = 'clickedCartProductQuantityDecrease',
  viewedRemoveProductPopup = 'viewedRemoveProductPopup',
  clickedCartProductCheckbox = 'clickedCartProductCheckbox',
  clickedCartOutOfStockMoreInfo = 'clickedCartOutOfStockMoreInfo',
  clickedCartPriceChangedMoreInfo = 'clickedCartPriceChangedMoreInfo',
  clickedRemoveProduct = 'clickedRemoveProduct',
  viewedAlertAboutRemovingFromCart = 'viewedAlertAboutRemovingFromCart',
  viewedSelectProductsToContinuePopup = 'viewedSelectProductsToContinuePopup',
  selectedOptionInAlertAboutRemoving = 'selectedOptionInAlertAboutRemoving',
  clickedContinueButtonInCart = 'clickedContinueButtonInCart',
  clickedRemoveSelectedProductsFromCart = 'clickedRemoveSelectedProductsFromCart',
  clickedMerchantInCart = 'clickedMerchantInCart',
  viewedFastBuyNow = 'viewedFastBuyNow',
  viewedMagnumBackdropRecommendations = 'viewedMagnumBackdropRecommendations',
  closeMagnumBackdropRecommendations = 'closeMagnumBackdropRecommendations',
  skipMagnumBackdropRecommendations = 'skipMagnumBackdropRecommendations',
  magnumBackdropRecommendationsAvailable = 'magnumBackdropRecommendationsAvailable',
  continueMagnumBackdropRecommendations = 'continueMagnumBackdropRecommendations',
  shareCartClick = 'shareCartClick',
  shareCartBackdropView = 'shareCartBackdropView',
  shareCartBackdropClick = 'shareCartBackdropClick',
  receiveCartBackdropView = 'receiveCartBackdropView',
  receivedCartAdd = 'receivedCartAdd',

  /* Checkout */
  viewedCheckoutPage = 'viewedCheckoutPage',
  clickedCheckoutPay = 'clickedCheckoutPay',
  clickedCheckoutPaymentButton = 'clickedCheckoutPaymentButton',
  updateCartWithDelivery = 'updateCartWithDelivery',
  viewedCheckoutDeliveryPage = 'viewedCheckoutDeliveryPage',
  viewedCheckoutDeliveryMapPage = 'viewedCheckoutDeliveryMapPage',
  updateCartWithPayment = 'updateCartWithPayment',
  clickedSpendBonus = 'clickedSpendBonus',
  viewedCheckoutPaymentPage = 'viewedCheckoutPaymentPage',
  viewedCheckoutPaymentConfirmationPage = 'viewedCheckoutPaymentConfirmationPage',
  viewedCheckoutLoanPage = 'viewedCheckoutLoanPage',
  viewedHybrisCheckoutLoanPage = 'viewedHybrisCheckoutLoanPage', // TODO: remove when checkout will be only on ms
  viewedCheckoutTrustedPersonPage = 'viewedCheckoutTrustedPersonPage',
  viewedCheckoutDecisionProgressPage = 'viewedCheckoutDecisionProgressPage',
  viewedCheckoutDecisionRejectedPage = 'viewedCheckoutDecisionRejectedPage',
  viewedCheckoutDecisionNewSumPage = 'viewedCheckoutDecisionNewSumPage',
  viewedCheckoutDecisionNewConditionsPage = 'viewedCheckoutDecisionNewConditionsPage',
  viewedCheckoutDecisionApprove = 'viewedCheckoutDecisionApprove',
  clickedOnApproveButton = 'clickedOnApproveButton',
  agreedToTheCreditAlternative = 'agreedToTheCreditAlternative',
  rejectedTheCreditAlternative = 'rejectedTheCreditAlternative',
  viewedCheckoutOrderConfirmPage = 'viewedCheckoutOrderConfirmPage',
  clickedOnTabBar = 'clickedOnTabBar',
  viewedCategoryNavigationPage = 'viewedCategoryNavigationPage',
  viewedCheckoutPostomatList = 'viewedCheckoutPostomatList',
  clickedCheckoutPostomat = 'clickedCheckoutPostomat',
  clickedCheckoutPostomatAddress = 'clickedCheckoutPostomatAddress',
  clickedCheckoutPostomatPickup = 'clickedCheckoutPostomatPickup',
  viewedCheckoutPostomatBackdrop = 'viewedCheckoutPostomatBackdrop',
  clickedCheckoutPostomatBackdrop = 'clickedCheckoutPostomatBackdrop',
  viewedCheckoutAlertError = 'viewedCheckoutAlertError',
  selectedDeliveryMethod = 'selectedDeliveryMethod',
  viewedNearbyPostomatToDeliveryPointBackdrop = 'viewedNearbyPostomatToDeliveryPointBackdrop',
  clickedAcceptNearbyPostomat = 'clickedAcceptNearbyPostomat',
  clickedRejectNearbyPostomat = 'clickedRejectNearbyPostomat',
  clickedMapNearbyPostomat = 'clickedMapNearbyPostomat',

  /* Listing */
  viewedCategoryListing = 'viewedCategoryListing',
  viewedSearchResults = 'viewedSearchResults',
  viewedFilterDialog = 'viewedFilterDialog',
  viewedFilterBackdrop = 'viewedFilterBackdrop',
  clickedApplyFilter = 'clickedApplyFilter',
  clickedResetFilters = 'clickedResetFilters',
  clickedOnCategoryTag = 'clickedOnCategoryTag',
  changedListingLayout = 'changedListingLayout',
  clickedListingSort = 'clickedListingSort',
  applyListingSort = 'applyListingSort',
  clickedOnCategoryTab = 'clickedOnCategoryTab',
  clickedResetFilerByCategory = 'clickedResetFilerByCategory',
  clickedShopModelTagViewed = 'clickedShopModelTagViewed',
  viewedShopModelTag = 'viewedShopModelTag',
  removeShopModelTag = 'removeShopModelTag',
  viewedProductWithDiscount = 'viewedProductWithDiscount',
  splitCartBackdropEvent = 'splitCartBackdropEvent',
  viewedLandingDresses = 'viewedLandingDresses',
  clickedModelLandingDresses = 'clickedModelLandingDresses',
  clickedBrandLandingDresses = 'clickedBrandLandingDresses',
  tireSelectionBannerClick = 'tireSelectionBannerClick',
  tireSelectionFlowView = 'tireSelectionFlowView',
  tireSelectionFlowSubmit = 'tireSelectionFlowSubmit',

  /* Review */
  clickedSubmitReviewForm = 'clickedSubmitReviewForm',
  clickedProductReviewLink = 'clickedProductReviewLink',
  shopProductReviewAddPageView = 'shopProductReviewAddPageView',
  shopProductReviewRatingSelect = 'shopProductReviewRatingSelect',
  shopProductReviewCommentEnter = 'shopProductReviewCommentEnter',
  shopProductReviewPhotoUploadClick = 'shopProductReviewPhotoUploadClick',
  shopProductReviewAdded = 'shopProductReviewAdded',
  viewedCompletedReviewForm = 'viewedCompletedReviewForm',
  uploadedPictureReview = 'uploadedPictureReview',
  clickedRemovedPictureReview = 'clickedRemovedPictureReview',
  clickedPhotoReview = 'clickedPhotoReview',
  clickProductReviewLike = 'clickProductReviewLike',
  clickReviewFilter = 'clickReviewFilter',
  startViewingProductReview = 'startViewingProductReview',
  finishViewingProductReview = 'finishViewingProductReview',

  /* Delivery */
  clickedDeliveryAddressSuggestion = 'clickedDeliveryAddressSuggestion',
  startedDeliveryAddressEntering = 'startedDeliveryAddressEntering',
  viewedDeliveryAddressSuggestions = 'viewedDeliveryAddressSuggestions',
  viewedNoDeliveryAddressSuggestions = 'viewedNoDeliveryAddressSuggestions',
  updateUserIsGeoEnabled = 'updateUserIsGeoEnabled',
  clickedDeliveryAddressOnGeoSuggestion = 'clickedDeliveryAddressOnGeoSuggestion',
  clickedMapOnGeoSuggestion = 'clickedMapOnGeoSuggestion',
  clickedMapLink = 'clickedMapLink',
  pickedAddressOnMap = 'pickedAddressOnMap',
  clickDeliverySlot = 'clickDeliverySlot',

  /* Recommendation */
  viewedRecommendation = 'viewedRecommendation',
  viewedRecommendationBanner = 'viewedRecommendationBanner',
  viewedBackdropRecommendation = 'viewedBackdropRecommendation',
  clickedBackdropRecommendation = 'clickedBackdropRecommendation',
  closedBackdropRecommendation = 'closedBackdropRecommendation',
  viewedBrandBlockRecommendation = 'viewedBrandBlockRecommendation',
  clickedBrandItemRecommendation = 'clickedBrandItemRecommendation',
  clickedBrandLinkRecommendation = 'clickedBrandLinkRecommendation',

  /* Orders */
  viewedMyOrders = 'viewedMyOrders',
  clickedButtonTimeline = 'clickedButtonTimeline',
  clickedOrderDetailsCancelButton = 'clickedOrderDetailsCancelButton',
  clickedOrderCancellationButton = 'clickedOrderCancellationButton',
  clickedMerchantPhoneLink = 'clickedMerchantPhoneLink',
  clickedOrderDetailsButton = 'clickedOrderDetailsButton',
  selectedCancellationReason = 'selectedCancellationReason',
  viewedOrderPage = 'viewedOrderPage',
  clickedTrackOrderButton = 'clickedTrackOrderButton',
  clickOrderDetailsCallMerchantButton = 'clickOrderDetailsCallMerchantButton',
  clickOrderDetailsCallCourierButton = 'clickOrderDetailsCallCourierButton',
  clickOrderDetailsPickupButton = 'clickOrderDetailsPickupButton',
  clickMyOrdersPickupButton = 'clickMyOrdersPickupButton',
  clickSupportChat = 'clickSupportChat',

  /* Search */
  viewedSearchForm = 'viewedSearchForm',
  viewedSearchHints = 'viewedSearchHints',
  shopSearchQueryStart = 'shopSearchQueryStart',
  clickOnSuggestion = 'clickOnSuggestion',
  magnumDiscountView = 'magnumDiscountView',

  /* Item Card */
  viewedItemCard = 'viewedItemCard',
  clickedItemCard = 'clickedItemCard',
  swipedCatalogItemImage = 'swipedCatalogItemImage',
  swipedProductImage = 'swipedProductImage',
  viewedButtonConvenientReturn = 'viewedButtonConvenientReturn',
  clickedBackdropConvenientReturn = 'clickedBackdropConvenientReturn',
  viewedBackdropConvenientReturn = 'viewedBackdropConvenientReturn',
  closeBackdropConvenientReturn = 'closeBackdropConvenientReturn',

  /* Product */
  viewedProductPage = 'viewedProductPage',
  clickedProductCta = 'clickedProductCta',
  clickedProductSelectOffer = 'clickedProductSelectOffer',
  viewedOfferMerchantWithDiscount = 'viewedOfferMerchantWithDiscount',
  viewedProductQuantityBackdrop = 'viewedProductQuantityBackdrop',
  viewedProductMerchantSelect = 'viewedProductMerchantSelect',
  viewedTryout = 'viewedTryout',
  viewedSellersOffers = 'viewedSellersOffers',
  viewedProductDescription = 'viewedProductDescription',
  viewedProductDescriptionTest = 'viewedProductDescriptionTest',
  viewedProductSpecifications = 'viewedProductSpecifications',
  viewedProductsOutOfStockAlert = 'viewedProductsOutOfStockAlert',
  viewedRepeatOrderProductsAdded = 'viewedRepeatOrderProductsAdded',
  viewedRepeatOrderMerchantBackdrop = 'viewedRepeatOrderMerchantBackdrop',
  viewedRepeatOrderAnalogGoodsBackdrop = 'viewedRepeatOrderAnalogGoodsBackdrop',
  viewedListingProductMerchants = 'viewedListingProductMerchants',
  viewedDeliveryFiltersApply = 'viewedDeliveryFiltersApply',
  viewedDeliveryFiltersReset = 'viewedDeliveryFiltersReset',
  viewedProductOffersPage = 'viewedProductOffersPage',
  viewedRepeatOrderProductsAddedAction = 'viewedRepeatOrderProductsAddedAction',
  clickedSizeBackdrop = 'clickedSizeBackdrop',
  clickedProductParameterChange = 'clickedProductParameterChange',
  viewedCrossSellBackdrop = 'viewedCrossSellBackdrop',
  closedCrossSellBackdrop = 'closedCrossSellBackdrop',
  clickedCrossSellItemCard = 'clickedCrossSellItemCard',
  viewedSizeBackdrop = 'viewedSizeBackdrop',

  viewedPaymentBalanceAlert = 'viewedPaymentBalanceAlert',
  viewedCreditApprovalInProgress = 'viewedCreditApprovalInProgress',
  viewedShopCardPayment = 'viewedShopCardPayment',
  viewedCreditApplicationForm = 'viewedCreditApplicationForm',

  shopMainPastOrderView = 'shopMainPastOrderView',
  shopMainPastOrdersClick = 'shopMainPastOrdersClick',
  shopMainMagnumPastOrderView = 'shopMainMagnumPastOrderView',
  shopLandingPageView = 'shopLandingPageView',
  clickMainPageBanner = 'clickMainPageBanner',

  viewedMainScreen = 'viewedMainScreen',
  viewedProductReviews = 'viewedProductReviews',
  viewedMainPromoBanner = 'viewedMainPromoBanner',
  clickMainPromoBanner = 'clickMainPromoBanner',
  viewedShopProductAlert = 'viewedShopProductAlert',
  clickShareProduct = 'clickShareProduct',

  viewedAuctionBanner = 'viewedAuctionBanner',
  clickAuctionBanner = 'clickAuctionBanner',

  clickedOffersSortBtn = 'clickedOffersSortBtn',
  appliedOffersSorting = 'appliedOffersSorting',
  viewedOffersDeliveryFiltersBackdrop = 'viewedOffersDeliveryFiltersBackdrop',
  appliedOffersFilter = 'appliedOffersFilter',
  resetOffersFilter = 'resetOffersFilter',
  startedOffersSearchQuery = 'startedOffersSearchQuery',
  viewedOffersSearchResult = 'viewedOffersSearchResult',

  /* Magnum Landing */
  viewedMagnumMainPageBanner = 'viewedMagnumMainPageBanner',
  clickMagnumMainPageBanner = 'clickMagnumMainPageBanner',
  viewedMagnumStretchedBanner = 'viewedMagnumStretchedBanner',
  clickMagnumStretchedBanner = 'clickMagnumStretchedBanner',

  /* Merchant Page */
  clickMerchantPhone = 'clickMerchantPhone',
  viewedShopMerchantPage = 'viewedShopMerchantPage',

  /* City */
  clickCityChange = 'clickCityChange',
  viewedCitySelectionDialog = 'viewedCitySelectionDialog',
  clickCitySelectionChosen = 'clickCitySelectionChosen',

  /* AB test */
  viewedExperiment = 'Viewed Experiment',

  /* Certificates */
  viewedCertificatesStories = 'viewedCertificatesStories',
  viewedCertificatesPreview = 'viewedCertificatesPreview',
  clickedGiftCertificate = 'clickedGiftCertificate',
  viewedCertificatesAlert = 'viewedCertificatesAlert',
}
