import { Api, OfferOptions } from '../../api';
import { AvailableZonesApiProvider } from '../apiProvider/AvailableZonesApiProvider';
import {
  IMutateProductCardTranslate,
  mutateProductCard,
  mutateProductCards,
} from '../apiProvider/helpers/mutateProductCards';
import { sortPromoBadges } from '../apiProvider/helpers/sortPromoBadges';
import { sortConfigBadges } from '../const/Badge';
import { CityCodes } from '../const/CityCodes';
import { CONSTANTS } from '../const/Constants';
import { getInstallIdForPCM } from '../helpers/getInstallId';
import { getCurrentCityId } from '../helpers/locationHelpers';
import { addAgeLimitToCard } from '../helpers/mutateCardsTitle';

class StaticTranslate {
  priceLiteral = 'PRICE_LITERAL';
  today = 'DELIVERY.TODAY';
  tomorrow = 'DELIVERY.TOMORROW';
  have = 'HAVE';
  deliveryExpress = 'DELIVERY.EXPRESS';
  deliveryTeaserTitle = 'DELIVERY.TEASER.TITLE';
  pickupTeaserTitle = 'PICKUP.TEASER.TITLE';
}

export class ProductCardService {
  readonly GOODS_LIST_CHUNK_SIZE = 20;
  readonly installId = getInstallIdForPCM();
  readonly userId = window.digitalData?.user?.userId;
  cityId: string = getCurrentCityId() || CityCodes.ALMATY;

  m: any;
  api: Api;
  t: StaticTranslate = new StaticTranslate();
  translation: IMutateProductCardTranslate;
  availableZoneProvider: AvailableZonesApiProvider;

  constructor(m: any) {
    this.m = m;
    this.api = new Api(m);

    this.translation = {
      priceLiteral: this.m.t.get(this.t.priceLiteral),
      today: this.m.t.get(this.t.today),
      tomorrow: this.m.t.get(this.t.tomorrow),
      have: this.m.t.get(this.t.have),
      deliveryExpress: this.m.t.get(this.t.deliveryExpress),
      deliveryTeaserTitle: this.m.t.get(this.t.deliveryTeaserTitle),
      pickupTeaserTitle: this.m.t.get(this.t.pickupTeaserTitle),
    };

    this.availableZoneProvider = new AvailableZonesApiProvider(m);
  }

  // get product info request
  async getGoodsRequest(
    productsSkus: string[],
    mUid?: string
  ): Promise<{ data: IItemCard[] }> {
    if (!productsSkus?.length) {
      return Promise.resolve({ data: [] });
    }

    const zoneIds = await this.availableZoneProvider.getAvailableZones();

    const payload: IGoodsParams = {
      productsSkus,
      mUid,
      i: this.installId,
      u: this.userId,
    };

    if (zoneIds?.length) {
      payload.zid = zoneIds.join(',');
    }

    return this.api.getGoods(payload);
  }

  // get offers request
  async getOffersRequest(
    entries: IGetOffersByMerchantPayloadEntry[] = []
  ): Promise<IOfferGateway[]> {
    if (!entries?.length) {
      return Promise.resolve([]);
    }

    const payload: IGetOffersByMerchantPayload = {
      options: [OfferOptions.PRICE],
      cityId: this.cityId,
      entries,
    };

    const zoneIds = await this.availableZoneProvider.getAvailableZones();
    if (zoneIds?.length) {
      payload.zoneId = zoneIds;
    }

    if (CONSTANTS.showDeliveryDurationDateInTeaser) {
      payload.options.push(OfferOptions.DELIVERY);
    }

    return this.api.getOffersByMerchant(payload);
  }

  private async getProductCards(
    skuArr: Array<string | number>,
    mUid?: string
  ): Promise<IItemCard[]> {
    let products: { data: IItemCard[] };
    const productsSkus = skuArr.map((sku) => String(sku));

    try {
      products = await this.getGoodsRequest(productsSkus, mUid);
    } catch (error) {
      console.error(error);
      return [];
    }

    if (!products?.data.length) {
      return [];
    }

    products.data.forEach((card) => this.updateCard(card));

    return products.data;
  }

  async getProductCardsByChunks(
    skuArr: Array<string | number>,
    mUid?: string
  ): Promise<IItemCard[]> {
    const chunks = [];

    for (let i = 0; i < skuArr.length; i += this.GOODS_LIST_CHUNK_SIZE) {
      const chunk = this.getProductCards(
        skuArr.slice(i, i + this.GOODS_LIST_CHUNK_SIZE),
        mUid
      );
      chunks.push(chunk);
    }

    const responseAll = await Promise.all(chunks);

    return responseAll.reduce((acc, cur) => acc.concat(cur), []);
  }

  private async getOffersForAdCards(
    cards: IAdsItemCard[]
  ): Promise<IAdsItemCard[]> {
    const offerEntries = this.getOfferEntriesFromAds(cards);
    let offers: IOfferGateway[];

    try {
      offers = await this.getOffersRequest(offerEntries);
    } catch (error) {
      console.error(error);
      return [];
    }

    const inStockCards = cards
      .map((card: IAdsItemCard) => {
        this.updateCard(card);
        return this.updateAdCardWithOffer(card, offers);
      })
      // remove out of stock
      .filter(Boolean);

    return inStockCards;
  }

  async getOffersForAdCardsByChunks(
    cards: IAdsItemCard[]
  ): Promise<IAdsItemCard[]> {
    const chunks = [];

    for (let i = 0; i < cards.length; i += this.GOODS_LIST_CHUNK_SIZE) {
      const chunk = this.getOffersForAdCards(
        cards.slice(i, i + this.GOODS_LIST_CHUNK_SIZE)
      );
      chunks.push(chunk);
    }

    const responseAll = await Promise.all(chunks);

    return responseAll.reduce((acc, cur) => acc.concat(cur), []);
  }

  private async getAdCardsWithOffers(
    adItems: IAdsPcmResponseItem[] | IAdsResponseItem[],
    adsUniqueId = ''
  ): Promise<IAdsItemCard[]> {
    let products: IItemCard[];
    let offers: IOfferGateway[];

    const cards = this.mutateAds(adItems);

    try {
      const productEntries = this.getProductEntries(cards);
      products = await this.getProductCards(productEntries);
    } catch (error) {
      console.error(error);
      return [];
    }

    // ! do not check offers length for brand ads
    if (!products?.length) {
      return [];
    }

    const adCards: IAdsItemCard[] = cards
      .map((card: IAdsCommonInfo) => {
        const productCard = products.find((item) => item.id === card.sku);
        if (!productCard) {
          return null;
        }
        return {
          ...productCard,
          sku: card.sku,
          adsSupplierId: card.adsSupplierId,
          campaignId: card.campaignId,
          adsUniqueId,
        };
      })
      .filter(Boolean);

    try {
      const offerEntries = this.getOfferEntriesFromAds(adCards);
      offers = await this.getOffersRequest(offerEntries);
    } catch (error) {
      console.error(error);
      return [];
    }

    const inStockCards = adCards
      .map((adsCard: IAdsItemCard) =>
        this.updateAdCardWithOffer(adsCard, offers)
      )
      // ! remove out of stock
      .filter(Boolean);

    return inStockCards;
  }

  async getAdCardsWithOffersByChunks(
    adItems: IAdsPcmResponseItem[] | IAdsResponseItem[],
    adsUniqueId = ''
  ): Promise<IAdsItemCard[]> {
    const chunks = [];

    for (let i = 0; i < adItems.length; i += this.GOODS_LIST_CHUNK_SIZE) {
      const chunk = this.getAdCardsWithOffers(
        adItems.slice(i, i + this.GOODS_LIST_CHUNK_SIZE),
        adsUniqueId
      );
      chunks.push(chunk);
    }

    const responseAll = await Promise.all(chunks);

    return responseAll.reduce((acc, cur) => acc.concat(cur), []);
  }

  mutateAds(ads: unknown[]): IAdsCommonInfo[] {
    if (!ads) {
      return [];
    }

    if (this.isAuctionAdVariant(ads[0])) {
      return this.mutateAuctionAds(ads as IAdsResponseItem[]);
    }

    if (this.isPcmAdVariant(ads[0])) {
      return this.mutatePcmAds(ads as IAdsPcmResponseItem[]);
    }

    return [];
  }

  isAuctionAdVariant(item: unknown): item is IAdsResponseItem {
    if (item && typeof item === 'object') {
      return 'id' in item;
    }
    return false;
  }

  isPcmAdVariant(item: unknown): item is IAdsPcmResponseItem {
    if (item && typeof item === 'object') {
      return 'sku' in item;
    }
    return false;
  }

  mutateAuctionAds(ads: IAdsResponseItem[] = []): IAdsCommonInfo[] {
    return ads.map((ad) => ({
      sku: ad.id.toString(),
      adsSupplierId: ad.merchant?.toString(),
      campaignId: ad.campaignId.toString(),
    }));
  }

  mutatePcmAds(ads: IAdsPcmResponseItem[] = []): IAdsCommonInfo[] {
    return ads.map((ad) => ({
      sku: ad.sku.toString(),
      adsSupplierId: ad.merchant_id?.toString(),
      campaignId: ad.campaign_id.toString(),
    }));
  }

  getProductEntries(cards: IAdsCommonInfo[]): string[] {
    return cards.map((card) => card.sku);
  }

  getOfferEntriesFromItemCards(
    cards: IItemCard[],
    merchantId?: string
  ): IGetOffersByMerchantPayloadEntry[] {
    return cards.map((card) => ({
      sku: card.id,
      merchantId,
      hasVariants: card.hasVariants ?? false,
    }));
  }

  getOfferEntriesFromAds(
    cards: IAdsItemCard[]
  ): IGetOffersByMerchantPayloadEntry[] {
    return cards
      .map((card) => {
        if (!card.adsSupplierId) {
          return null;
        }
        return {
          sku: card.sku,
          merchantId: card.adsSupplierId,
          hasVariants: card.hasVariants ?? false,
        };
      })
      .filter(Boolean);
  }

  updateCard(card: IItemCard): void {
    if (card.promo?.length > 1) {
      card.promo = sortPromoBadges({
        config: sortConfigBadges,
        badges: card.promo,
      });
    }

    addAgeLimitToCard(card);
  }

  updateAdCardWithOffer(
    card: IAdsItemCard,
    offers: IOfferGateway[] = []
  ): IAdsItemCard {
    // ! brand promo ads
    if (!card.adsSupplierId) {
      return card;
    }

    const offer: IOfferGateway = offers.find(
      (offerItem: IOfferGateway) =>
        offerItem.merchantId === card.adsSupplierId &&
        (offerItem.masterSku === card.sku || offerItem.groupedSku === card.sku)
    );

    // ! do not show ad with no offer
    if (!offer) {
      return null;
    }

    const mutatedCard = {
      ...card,
    };

    mutateProductCard({
      card: mutatedCard,
      offer,
      merchantId: offer.merchantId,
      translation: this.translation,
    });

    return mutatedCard;
  }

  async updatePriceByMerchant(
    goods: IItemCard[],
    merchantId: string
  ): Promise<IItemCard[]> {
    const offerEntries = this.getOfferEntriesFromItemCards(goods, merchantId);

    let offersByMerchant: IOfferGateway[];

    try {
      offersByMerchant = await this.getOffersRequest(offerEntries);
    } catch (error) {
      console.error(error);
      return [];
    }

    mutateProductCards({
      products: goods,
      offers: offersByMerchant,
      merchantId,
      translation: this.translation,
    });

    return goods.filter((product) => product.merchantId);
  }

  async getAdAndProductCards(
    list: IAdsResponseItem[] | IAdsPcmResponseItem[] | string[],
    blockEventId?: string,
    merchantIdToGetOffers?: string
  ): Promise<IAdsItemCard[] | IItemCard[]> {
    const adItems: IAdsResponseItem[] = [];
    const nonAdItems: string[] = [];
    list.forEach((item) => {
      if (this.isAdItem(item)) {
        adItems.push(item as IAdsResponseItem);
      } else {
        nonAdItems.push(this.extractId(item));
      }
    });

    const [adCards, nonAdCards] = await Promise.all([
      this.getAdCardsWithOffersByChunks(adItems, blockEventId),
      this.getProductCardsByChunks(nonAdItems, merchantIdToGetOffers),
    ]);

    const mergedCards: Array<IAdsItemCard | IItemCard> = [
      ...adCards,
      ...nonAdCards,
    ];

    const cardMap = new Map(
      mergedCards.map((card) => [this.extractId(card), card])
    );

    return list
      .map((item) => cardMap.get(this.extractId(item)))
      .filter(Boolean);
  }

  isAdItem(item: IAdsResponseItem | string): boolean {
    if (!item || typeof item !== 'object') {
      return false;
    }

    const adKeys = ['campaignId', 'merchant', 'campaign_id', 'merchant_id'];
    return adKeys.some((key) => key in item);
  }

  extractId(item: IAdsPcmResponseItem | IAdsResponseItem | string) {
    if (this.isAuctionAdVariant(item)) {
      return item.id;
    }
    if (this.isPcmAdVariant(item)) {
      return item.sku;
    }
    return item;
  }
}
